const initialState = () => {
  return {
    //  array of section object
    sections: [],
    availableScenarios: [],
    actualScenario: null,
    actualScenarioStep: null,
    numberOfScenarioSteps: null
  };
};
const state = initialState();

// getters
const getters = {
  availableScenarios: state => state.availableScenarios,
  scenarioSections: state => state.sections,
  actualScenario: state => state.actualScenario,
  actualScenarioStep: state => state.actualScenarioStep,
  numberOfScenarioSteps: state => state.numberOfScenarioSteps
};
// actions
const actions = {
  initScenarioExercice({ commit }, properties) {
    commit("init", properties);
  },
  // Set current scenario
  setActualScenario({ commit, state }, actualScenario) {
    const scenario = state.sections[actualScenario];
    let data = {
      step: scenario,
      numberOfSteps: scenario.length
    };
    commit("setScenario", data);
  },
  setActualScenarioStep({ commit, state }, actualStep) {
    const step = state.actualScenario[actualStep];
    commit("setScenarioStep", step);
  },
  successiveAnswer({ commit, state }, answer) {
    if (state.availableResponses.indexOf(answer) !== -1) {
      let answers = state.answers;
      answers.push(answer);
      commit("storeAnswer", answers);
    } else {
      throw "Is not an available answer";
    }
  },
  resetScenario({ commit }) {
    commit("resetState");
  }
};

// mutations
const mutations = {
  ["init"](state, properties) {
    state.availableScenarios = properties["available-scenarios"];
    state.sections = properties.sections;
  },
  ["setScenario"](state, data) {
    state.actualScenario = data.step;
    state.numberOfScenarioSteps = data.numberOfSteps;
  },
  ["setScenarioStep"](state, step) {
    state.actualScenarioStep = step;
  },
  ["resetState"](state) {
    Object.assign(state, initialState());
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
