<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32.836"
    height="32.836"
    viewBox="0 0 32.836 32.836"
  >
    <path
      d="M38.836,20.366H13.86L25.332,8.894,22.418,6,6,22.418,22.418,38.836l2.894-2.894L13.86,24.47H38.836Z"
      transform="translate(-6 -6)"
    />
  </svg>
</template>

<script>
export default {
  name: "arrowBack"
};
</script>
