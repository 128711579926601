import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

// const lazyLoad = view => {
//   return () => import(`@/views/${view}.vue`);
// };

const routes = [
  {
    path: "/",
    name: "Home",
    component: () => import(`@/views/Home/home.vue`)
  },
  {
    path: "/cgu-mobile",
    name: "cgu-mobile",
    component: () => import("../views/CGU/mobile.vue")
  },
  {
    path: "/hydro-cortisone",
    name: "Chapter-one",
    component: () => import(`@/views/ChapterOne/chapterOne.vue`),
    props: route => ({ wordIndex: route.query.q })
  },
  {
    path: "/je-m-adapte",
    name: "Chapter-two",
    component: () => import(`@/views/ChapterTwo/chapterTwo.vue`),
    props: route => ({ wordIndex: route.query.q, showSynthesis: route.query.s })
  },
  {
    path: "/je-suis-malade",
    name: "Chapter-three",
    component: () => import(`@/views/ChapterThree/chapterThree.vue`),
    props: route => ({
      section: route.query.q,
      step: route.query.step,
      showSynthesis: route.query.s
    })
  },
  {
    path: "/mon-injection",
    name: "Chapter-four",
    component: () => import(`@/views/ChapterFour/chapterFour.vue`)
  },
  {
    path: "/ma-trousse-d-urgence",
    name: "Chapter-five",
    component: () => import(`@/views/ChapterFive/chapterFive.vue`),
    props: route => ({ wordIndex: route.query.q })
  }
];

const router = new VueRouter({
  routes
});

export default router;
