import Vue from "vue";

import arrowBack from "./arrowBack";
import filePicture from "./filePicture";
import closeIcon from "./closeIcon";
import openPoso from "./openPoso";

Vue.component(arrowBack.name, arrowBack);
Vue.component(filePicture.name, filePicture);
Vue.component(closeIcon.name, closeIcon);
Vue.component(openPoso.name, openPoso);
