export function set(name, value) {
  const response = window.localStorage.getItem(name);
  if (response === null) {
    window.localStorage.setItem(name, JSON.stringify(value));
    return true;
  }
  return false;
}

export function get(name) {
  const response = window.localStorage.getItem(name);
  if (response !== null) {
    return response;
  }
  throw "NOT_FOUND";
}

export const StorageHelper = {
  set,
  get
};
