import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

import findAssociationModule from "./modules/findAssociationModule";
import appModule from "./modules/appModule";
import successiveQuestionsModule from "./modules/successiveQuestionsModule";
import scenarioModule from "./modules/scenarioModule";

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    appModule,
    findAssociationModule,
    successiveQuestionsModule,
    scenarioModule
  }
});
