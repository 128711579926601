<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="29.828"
    height="29.828"
    viewBox="0 0 29.828 29.828"
  >
    <g transform="translate(-351.086 -174.086)">
      <line
        x2="27"
        y2="27"
        transform="translate(352.5 175.5)"
        fill="none"
        stroke="#ffffff"
        stroke-linecap="round"
        stroke-width="2"
      />
      <line
        x1="27"
        y2="27"
        transform="translate(352.5 175.5)"
        fill="none"
        stroke="#ffffff"
        stroke-linecap="round"
        stroke-width="2"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: "closeIcon"
};
</script>
<style lang="scss" scoped>
svg {
  filter: drop-shadow(0px 6px 3px rgba(27, 33, 58, 0.4));
}
</style>
