<template>
  <img
          src="@/assets/schemas-poso/posologie-logo.png"
          alt="logo schéma posologie"
          class="img-fluid"
  />
</template>

<script>
export default {
  name: "filePicture"
};
</script>
<style lang="scss" scoped></style>
