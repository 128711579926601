const initialState = () => {
  return {
    availableResponses: [],
    words: {},
    actualStep: null,
    answers: [],
    numberOfSteps: null,
    synthesisText: null
  };
};
const state = initialState();

// getters
const getters = {
  availableResponses: state => state.availableResponses,
  words: state => state.words,
  actualStep: state => state.actualStep,
  numberOfSteps: state => state.numberOfSteps,
  answers: state => state.answers,
  synthesisText: state => state.synthesisText
};
// actions
const actions = {
  // set constants to store from api
  initExercice({ commit }, properties) {
    properties.numberOfSteps = Object.keys(properties.words).length;
    commit("init", properties);
    return properties.numberOfSteps;
  },
  // Set word to show on exercice
  setActualStep({ commit, state }, actualStep) {
    const step = Object.values(state.words)[actualStep];
    commit("setStep", step);
  },
  answer({ commit, state }, answer) {
    if (state.availableResponses.indexOf(answer) !== -1) {
      let answers = state.answers;
      answers.push(answer);
      commit("storeAnswer", answers);
    } else {
      throw "Is not an available answer";
    }
  },
  removeLastAnswer({ commit, state }, index) {
    let answers = state.answers;
    answers.pop();
    if (answers.length === 0 && index === 1) {
      commit("resetState");
    } else {
      commit("storeAnswer", answers);
    }
  },
  resetFindAssociation({ commit }) {
    // const resetedState = Object.assign(state, initialState);
    // console.log(resetedState);
    commit("resetState");
  }
};

// mutations
const mutations = {
  ["init"](state, properties) {
    state.availableResponses = properties["available-responses"];
    state.words = properties.words;
    state.numberOfSteps = properties.numberOfSteps;
    state.synthesisText = properties.synthesisText;
  },
  ["setStep"](state, step) {
    state.actualStep = step;
  },
  ["storeAnswer"](state, answers) {
    state.answers = answers;
  },
  ["resetState"](state) {
    Object.assign(state, initialState());
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
