<template>
  <div id="app" :class="platform">
    <modal name="intro" adaptive height="auto" width="80%" classes="modal-content">
      <div slot="top-right" class="close-icon">
        <closeIcon @click.native="$modal.hide('intro')"></closeIcon>
      </div>
      <h2>Petit mot d'introduction</h2>
      <p>
        Vivre avec une insuffisance surrénale vous demande de vous adapter sans
        cesse. Identifier vos symptômes, adapter vos traitements, faire face aux
        situations à risque, gérer votre stress, savoir faire son injection
        d’hydrocortisone ; tout ceci est votre quotidien.
      </p>
      <br />
      <p>
        CAP surrénales est une application qui vous permettra de vous exercer,
        après les ateliers d’éducation thérapeutique sur tous ces sujets.
      </p>
    </modal>
    <transition name="fade">
      <router-view v-show="isAppReady" />
    </transition>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapGetters } from "vuex";
import closeIcon from "./components/icons/closeIcon";
import { get, set } from "./helpers/storageHelper";

export default {
  name: "mainApp",
  components: {
    closeIcon
  },
  computed: {
    ...mapGetters(["isAppReady", "platform"])
  },
  async created() {
    if (!this.isAppReady) {
      await this.$store.dispatch("setAppReady");
    }
    if (window.cordova) {
      // eslint-disable-next-line no-undef
      await this.$store.dispatch("setPlatform", device.platform);
    } else {
      await this.$store.dispatch("setPlatform", navigator.platform);
    }
  },
  mounted() {
    // Show modal on first opening app
    try {
      get("alreadyOpen");
    } catch (e) {
      if (e === "NOT_FOUND") {
        this.$modal.show("intro");
        set("alreadyOpen", true);
      }
    }
  }
};
</script>

<style lang="scss">
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.25s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
