const state = {
  appReady: false,
  platform: null
};

// getters
const getters = {
  isAppReady: state => state.appReady,
  platform: state => state.platform
};
// actions
const actions = {
  // set constants to store from api
  setAppReady({ commit }) {
    commit("SET_APP_READY");
  },
  setPlatform({ commit }, platform) {
    commit("SET_PLATFORM", platform);
  }
};

// mutations
const mutations = {
  ["SET_APP_READY"](state) {
    state.appReady = true;
  },
  ["SET_PLATFORM"](state, platform) {
    state.platform = platform;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
