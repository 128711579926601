<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6 0H24V18H6V0ZM7 1H23V17H7V1ZM10.91 12.3731L20.2807 3H14.9917V2H21.99V8.99019H20.9902V3.70453L11.617 13.0802L10.91 12.3731ZM18 22H17V23H16V24H18V22ZM0 22H1V23H2V24H0V22ZM13 23H15V24H13V23ZM10 23H12V24H10V23ZM7 23H9V24H7V23ZM3 23H6V24H3V23ZM0 18H1V21H0V18ZM0 15H1V17H0V15ZM0 12H1V14H0V12ZM0 9H1V11H0V9ZM3 6H5V7H3V6ZM17 19H18V21H17V19ZM0 8H1V7H2V6H0V8Z"
      fill="black"
    />
  </svg>
</template>

<script>
export default {
  name: "openPoso"
};
</script>
<style lang="scss" scoped></style>
