import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";

import "@/styles/_app.scss";
// Register icons on application
import "@/components/icons";

import VueSilentbox from "vue-silentbox";
Vue.use(VueSilentbox);
import VueJSModal from "vue-js-modal";
Vue.use(VueJSModal);

Vue.config.productionTip = false;
// init of the Vue app
function initApp() {
  new Vue({
    router,
    store,
    render: h => h(App)
  }).$mount("#app");
}
// check if we are in a Cordova context
if (window.cordova) {
  // if so, wait for the device ready event
  document.addEventListener(
    "deviceready",
    () => {
      initApp();
    },
    false
  );
} else {
  initApp();
}
