const initialState = () => {
  return {
    //  array of section object
    sections: [],
    availableResponses: [],
    actualStep: null,
    sectionAnswers: [],
    numberOfSteps: null
  };
};
const state = initialState();

// getters
const getters = {
  availableSuccessiveResponses: state => state.availableResponses,
  sections: state => state.sections,
  actualSuccessiveStep: state => state.actualStep,
  numberOfSuccessiveSteps: state => state.numberOfSteps,
  sectionAnswers: state => state.answers
};
// actions
const actions = {
  // set constants to store from api
  initSuccessiveExercice({ commit }, properties) {
    properties.numberOfSteps = properties.sections.length;
    commit("init", properties);
    return properties.numberOfSteps;
  },
  // Set word to show on exercice
  setSuccessiveActualStep({ commit, state }, actualStep) {
    const step = state.sections[actualStep];
    commit("setStep", step);
  },
  successiveAnswer({ commit, state }, answer) {
    if (state.availableResponses.indexOf(answer) !== -1) {
      let answers = state.answers;
      answers.push(answer);
      commit("storeAnswer", answers);
    } else {
      throw "Is not an available answer";
    }
  },
  resetFindAssociation({ commit }) {
    commit("resetState");
  }
};

// mutations
const mutations = {
  ["init"](state, properties) {
    state.availableResponses = properties["available-responses"];
    state.sections = properties.sections;
    state.numberOfSteps = properties.numberOfSteps;
  },
  ["setStep"](state, step) {
    state.actualStep = step;
  },
  ["resetState"](state) {
    Object.assign(state, initialState());
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
